/* eslint-disable no-param-reassign */
/* eslint-disable no-extend-native */

// 날짜 출력(yyyy-mm-dd)
Date.prototype.format = function (separator) {
  if (!separator) separator = '-';
  const yyyy = this.getFullYear().toString();
  let mm = (this.getMonth() + 1).toString();
  mm = mm[1] ? mm : `0${mm[0]}`;
  let dd = this.getDate().toString();
  dd = dd[1] ? dd : `0${dd[0]}`;

  return yyyy + separator + mm + separator + dd;
};

// 날짜 출력(yyyy-mm-dd HH:ii:ss)
Date.prototype.formatYYYYMMDDHHmmss = function (separator) {
  let HH = this.getHours().toString();
  HH = HH[1] ? HH : `0${HH[0]}`;
  let ii = this.getMinutes().toString();
  ii = ii[1] ? ii : `0${ii[0]}`;
  let ss = this.getSeconds().toString();
  ss = ss[1] ? ss : `0${ss[0]}`;

  return `${this.format(separator)} ${HH}:${ii}:${ss}`;
};

// 날짜 출력(yyyymmdd)
Date.prototype.formatYYYYMMDD = function () {
  const yyyy = this.getFullYear().toString();
  let mm = (this.getMonth() + 1).toString();
  mm = mm[1] ? mm : `0${mm[0]}`;
  let dd = this.getDate().toString();
  dd = dd[1] ? dd : `0${dd[0]}`;
  return yyyy + mm + dd;
};

// 날짜 출력(yyyymmddHHiiss)
Date.prototype.noSeparatorFormatYYYYMMDDHHmmss = function () {
  let HH = this.getHours().toString();
  HH = HH[1] ? HH : `0${HH[0]}`;
  let ii = this.getMinutes().toString();
  ii = ii[1] ? ii : `0${ii[0]}`;
  let ss = this.getSeconds().toString();
  ss = ss[1] ? ss : `0${ss[0]}`;

  return this.formatYYYYMMDD() + HH + ii + ss;
};

const date = {
  // tzo는 네이티브가 아닌 브라우저에서 추출
  getTzo() {
    const today = new Date();
    return -1 * today.getTimezoneOffset();
  },

  // TODO ken impl
  changeSecsToMin(secs) {
    // 초를 분으로 만들어서 리턴
    let min = 0;
    // eslint-disable-next-line no-return-assign
    return secs < 60 ? min : (min = Math.floor(secs / 60));
  },

  toLocalDate(dbDate) {
    // YYYY-MM-DD HH:mm:ss 형식
    const from =
      // eslint-disable-next-line eqeqeq
      typeof dbDate == 'string' ? date.stringToDate(dbDate) : dbDate;
    const to = new Date(from.getTime() - (540 + from.getTimezoneOffset()) * 60000);
    return to;
  },

  toDateForDB(localDate) {
    // YYYY-MM-DD HH:mm:ss 형식
    const from =
      // eslint-disable-next-line eqeqeq
      typeof localDate == 'string' ? date.stringToDate(localDate) : localDate;
    const to = new Date(from.getTime() + (540 + from.getTimezoneOffset()) * 60000);
    return to;
  },

  stringToDate(dateString) {
    // eslint-disable-next-line no-shadow
    const date = dateString.split('-').join('/'); // '2015-05-22 09:57:21' => '2015/05/22 09:57:21'
    return new Date(date);
  },

  // 년 월 일
  formatYMD(dateOrDateStr, doHumanize) {
    // eslint-disable-next-line no-shadow
    const date = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    if (doHumanize) {
      // 2018년 1월 31일, "Jan 31, 2018"
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return date.toLocaleString(navigator.language, options);
    }
    // 2018-01-31
    return date.format();
  },
  // 년 월 일 요일
  formatYMDW(dateOrDateStr, doHumanize) {
    // eslint-disable-next-line no-shadow
    const date = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    if (doHumanize) {
      // 2018년 1월 31일 (수), "WED, Jan 31, 2018"
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
      };
      return date.toLocaleString(navigator.language, options);
    }
    // 2018-01-31
    return `${date.format()} (${date.toLocaleString(navigator.language, {
      weekday: 'short',
    })})`;
  },

  // 년 월 일 요일 시 분 표기
  formatYMDWHi(dateOrDateStr, doHumanize) {
    // eslint-disable-next-line no-shadow
    const date = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    if (doHumanize) {
      // 2018년 1월 31일 (수), "WED, Jan 31, 2018"
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
      };
      return date.toLocaleString(navigator.language, options);
    }
    // 2018-01-31
    return `${date.format()} (${date.toLocaleString(navigator.language, {
      weekday: 'short',
    })}), ${date.formatHHmm()}`;
  },
  // 월 일 요일 표기
  formatMDW(dateOrDateStr, doHumanize) {
    // eslint-disable-next-line no-shadow
    const date = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    if (doHumanize) {
      // 1/31(수), "WED,Jan 31"
      const options = { month: 'short', day: 'numeric', weekday: 'short' };
      return date.toLocaleString(navigator.language, options);
    }
    // 01-31(수)
    return `${date.formatMMDD()} (${date.toLocaleString(navigator.language, {
      weekday: 'short',
    })})`;
  },
  // 월 일  시 분 표기
  formatMDHi(dateOrDateStr, doHumanize) {
    // eslint-disable-next-line no-shadow
    const date = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    if (doHumanize) {
      // 2018년 1월 31일 (수), "WED, Jan 31, 2018"
      const options = {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return date.toLocaleString(navigator.language, options);
    }
    // 2018-01-31
    return `${date.format()} (${date.toLocaleString(navigator.language, {
      weekday: 'short',
    })}), ${date.formatHHmm()}`;
  },
  // 시 분 표기
  formatHi(dateOrDateStr) {
    // eslint-disable-next-line no-shadow
    const date = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    const options = { hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString(navigator.language, options);
  },
  isItBetweenDate(endDateStr, startDateStr) {
    const endDate = typeof endDateStr === 'string' ? date.stringToDate(endDateStr) : endDateStr;
    const startDate = typeof startDateStr === 'string' ? date.stringToDate(startDateStr) : startDateStr;
    const nowDate = new Date();
    if (endDate.getTime() >= nowDate.getTime() && startDate.getTime() < nowDate.getTime()) return true;
    return false;
  },
  isValidPeriod(endDateStr) {
    // endDate : 끝나는 날짜,
    const startDate = new Date();
    const endDate = typeof endDateStr === 'string' ? date.stringToDate(endDateStr) : endDateStr;

    if (startDate.getTime() <= endDate.getTime()) return true;
    return false;
  },
  isPreviousDate(targetDt, baseDt) {
    const baseDate = typeof baseDt === 'string' ? date.stringToDate(baseDt) : baseDt;
    const targetDate = typeof targetDt === 'string' ? date.stringToDate(targetDt) : targetDt;

    if (targetDate.getTime() <= baseDate.getTime()) return true;
    return false;
  },
  addDays(dateOrDateStr, days) {
    // eslint-disable-next-line no-shadow
    const date = new Date();
    const targetDate = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;

    date.setTime(targetDate.getTime() + 60 * 60 * 24 * 1000 * days);
    return date;
  },
  addMonths(dateOrDateStr, months) {
    const targetDate = typeof dateOrDateStr === 'string' ? this.stringToDate(dateOrDateStr) : dateOrDateStr;
    // eslint-disable-next-line no-shadow
    const date = new Date(targetDate.getTime());

    date.setMonth(targetDate.getMonth() + months);
    return date;
  },
  addHours(targetDate, hours) {
    // eslint-disable-next-line no-shadow
    const date = new Date();
    date.setTime(targetDate.getTime() + 60 * 60 * 1000 * hours);
    return date;
  },
  inDays(baseDate, targetDate) {
    const targetTS = targetDate.getTime();
    const thisTS = baseDate.getTime();
    return Math.floor((targetTS - thisTS) / (24 * 3600 * 1000));
  },
  amPm(data) {
    const originTime = data;
    const splitTime = originTime.split(':');

    const amCheck = Number(splitTime[0]) > 11 ? 1 : 0;
    const minData = Number(splitTime[0]) > 12 ? Number(splitTime[0]) - 12 : Number(splitTime[0]);
    const secData = splitTime[1];

    return [amCheck, `${minData}:${secData}`];
  },
};

export default date;
