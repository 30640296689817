export const PG_TYPE = {
  NICEPAY: 'nicepay',
  UPLUS: 'uplus',
  PAYMENTWALL: 'paymentwall',
} as const;

export const LANGUAGE = {
  KR: 'kr',
  EN: 'en',
  CN: 'cn',
  JP: 'jp',
} as const;

export const PLAN_CODE = {
  /**
   *  [ 튜터링 ]
   */
  ALLPASS: 'allpass',
  ALLPASS_NEW: 'allpass_new',
  ALLPASS_REPURCHASE: 'allpass_repurchase',
  ALPHA_PLAN: 'alpha', // 단과패스
  TUTORING_PLAY: 'play',
  MINI_BOOK_PLAN: '202107_mini',
  JICDING_ENGLISHTECH: 'renewaljicding',
  EVENT_2021FOCUSPACK: '202103_focuspack',
  /**
   *  [ 쨔요 ]
   */
  DAWATDA_PASS: 'dawatda_pass', // 다왓다
  REAL_BEGINNER_PLAN: 'event_17', // 찐초보
  /**
   *  [ 공통 ]
   *   - 글로벌 유저 (튜터링, 짜요, 장기, 단기 플랜 전부 다 해당)
   *   - 쨔요 패스
   */
  BASIC: 'cp',
} as const;

export const PAY_METHOD = {
  CARD: 'C',
  PHONE: 'P',
  VIRTUAL_ACCOUNT: 'DB',
  PAYMENTWALL: 'PW',
  SUBSCRIBE: 'SP',
} as const;

export type TypePgType = typeof PG_TYPE[keyof typeof PG_TYPE];
export type TypeLanguage = typeof LANGUAGE[keyof typeof LANGUAGE];
export type TypePlanCode = typeof PLAN_CODE[keyof typeof PLAN_CODE];
export type TypePayMethod = typeof PAY_METHOD[keyof typeof PAY_METHOD];

export type TypePayment = {
  mtIdx: string;
  code: TypePlanCode;
  titleForPG: string;
  payMethod: TypePayMethod;
  totalPaidMoney: number;
  months: number;
};
