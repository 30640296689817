import api from '@/common/api';
import { TypePayment, TypePgType } from './interface';

export interface IPayment {
  paymentInfo: TypePayment;
  pgType: TypePgType;
  init(): void;
  getPgForm(): Promise<string>;
  loadPgForm(): void;
  fillPgForm(): void;
  submitPgForm(): void;
}

export abstract class Payment implements IPayment {
  _paymentInfo: TypePayment;

  _pgType: TypePgType;

  get paymentInfo(): TypePayment {
    return this._paymentInfo;
  }

  get pgType(): TypePgType {
    return this._pgType;
  }

  constructor(paymentInfo: TypePayment, pgType: TypePgType) {
    this._paymentInfo = paymentInfo;
    this._pgType = pgType;
  }

  init(): Promise<void> {
    return new Promise((resolve) => {
      resolve();
    });
  }

  async getPgForm(): Promise<string> {
    try {
      const pg = this._pgType;
      const formHtmlString = await api.get('/mvc/get_pay_form', { pg });
      return formHtmlString as unknown as string;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  async loadPgForm(): Promise<void> {
    const body = document.querySelector('body');
    const formWrapper = document.createElement('div');
    const formHtmlString = await this.getPgForm();
    formWrapper.id = 'pg-form';
    formWrapper.innerHTML = formHtmlString;
    body?.appendChild(formWrapper);
  }

  fillPgForm(): void {}

  submitPgForm(): void {}
}
